import React, { useContext, useEffect, useState } from "react";
import ListFilter from "src/components/filters/ListFilter";
import {
  OpportunityContext,
  OpportunityContextType,
} from "src/components/contexts/opportunityContext";
import { useRouter } from "next/router";
import { SignUpModal } from "src/components/Modal";
import { useMediaQuery } from "@material-ui/core";
import {
  AccountContext,
  AccountContextType,
} from "src/components/contexts/ToggleAccountContext";
import MobileFooter from "src/components/MobileFooter";
import {
  PageContext,
  PageContextType,
} from "src/components/contexts/PageContext";
import HomeOpportunities from "src/components/Feed/HomeOpportunities";
export default function Home() {
  const { feed, showSignUpModel, setShowSignUpModel } =
    useContext<OpportunityContextType>(OpportunityContext);
  const { accountInfo } = useContext<AccountContextType>(AccountContext);
  const { isBusiness } = useContext<AccountContextType>(AccountContext);
  const [isShowSignUp, setShowSignUp] = useState(true);

  const isMobile = useMediaQuery("(max-width: 900px)");
  function handleSignUpPopUp() {
    setShowSignUp(false);
    setShowSignUpModel(!showSignUpModel);
  }

  useEffect(() => {
    {
      if (showSignUpModel) {
        setShowSignUp(true);
        setShowSignUpModel(true);
      } else {
        setShowSignUp(false);
        setShowSignUpModel(false);
      }
    }
  }, [setShowSignUpModel, showSignUpModel]);
  const router = useRouter();
  useEffect(() => {
    if (!accountInfo) return;
    if (isBusiness) {
      router.push(`/merchant/${accountInfo.id}`);
    }
  }, [accountInfo, isBusiness, router]);
  const { setBreadcrumb, setPageTitle, setIsHiddenHeader } =
    useContext<PageContextType>(PageContext);
  useEffect(() => {
    setPageTitle("");
    setBreadcrumb({ label: "", noBack: true });
    setIsHiddenHeader(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [feed]);

  if (isBusiness) {
    return;
  }

  return (
    <>
      <div className=" pb-5 flex w-full justify-between md:pb-12 md:justify-center">
        <ListFilter />
      </div>
      <div className={`mx-5 flex justify-center md:mx-0`}>
        <HomeOpportunities filterType={feed}/>
      </div>
      {isMobile && <MobileFooter />}

      <SignUpModal open={showSignUpModel} onClose={() => handleSignUpPopUp()} />
    </>
  );
}
