import { DeleteObjectCommand, PutObjectCommand, S3 } from "@aws-sdk/client-s3";
import { env } from "src/env/client.mjs";

const s3Client = () => {
  return new S3({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: "https://sfo3.digitaloceanspaces.com",
    region: "sfo",
    credentials: {
      accessKeyId: env.NEXT_PUBLIC_SPACES_KEY,
      secretAccessKey: env.NEXT_PUBLIC_SPACES_SECRET,
    },
  });
};

export const uploadImage = async (type: any, image_data1: any, id: any) => {
  const uploadParams = {
    Bucket: "mindkind-assets",
    Key: `${id}_${Date.now()}`,
    Body: new Buffer(
      image_data1.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    ),
    ContentType: type,
    ACL: "public-read",
  };
  try {
    const client = s3Client();
    await client.send(new PutObjectCommand(uploadParams));
    return `https://mindkind-assets.sfo3.cdn.digitaloceanspaces.com/${uploadParams.Key}`;
  } catch (error) {
    console.error("Upload failed: ", error);
    return null;
  }
};

export const deleteImage = async (key: string) => {
  const imageId = key.replace(
    "https://mindkind-assets.sfo3.cdn.digitaloceanspaces.com/",
    ""
  );
  const deleteParams = {
    Bucket: "mindkind-assets",
    Key: imageId,
  };
  try {
    const client = s3Client();
    return await client.send(new DeleteObjectCommand(deleteParams));
  } catch (error) {
    console.error("Deletion failed: ", error);
    return null;
  }
};
