import { JackpotPreview } from "src/types/models";
import SwiperContainer from "../Image/SwiperContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { default as CommonImage } from "../Image/Image";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { formatCurrency } from "src/utils";
import { useRef } from "react";
import { useFilterContext } from "../contexts/FilterContext/FilterContext";

const JackpotCard = ({
  jackpot,
  imagePriority,
}: {
  jackpot: JackpotPreview;
  imagePriority: boolean;
}) => {
  const { selectJackpot } = useFilterContext();
  const images = jackpot.media;
  const ref = useRef<HTMLDivElement>(null);

  if (!images) {
    return null;
  }

  return (
    <>
      <article
        className="cursor-pointer pt-[17px]"
        ref={ref}
        onClick={() => selectJackpot(jackpot)}
      >
        <div className="w-full">
          <SwiperContainer>
            <Swiper
              className="aspect-[1/1]"
              spaceBetween={20}
              slidesPerView={1}
              modules={[Navigation, Pagination]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={!!jackpot}
              loop
              // There is an issue in iPhone that clicking doesn't propagate the event if the arrow are not shown
              // Here we click the parent component which will always propagate accordingly
              onClick={() => ref.current?.click()}
            >
              <>
                <SwiperSlide>
                  <CommonImage src={images[0] || ""} priority={imagePriority} />
                </SwiperSlide>
                <SwiperSlide>
                  <CommonImage src={images[1] || ""} />
                </SwiperSlide>
              </>

              <div className="swiper-button-next" onClick={(e) => e.stopPropagation()} />
              <div className="swiper-button-prev" onClick={(e) => e.stopPropagation()} />
            </Swiper>
          </SwiperContainer>
        </div>
        <div className="mt-1 flex sm:mt-3">
          <div className="w-full">
            <h3 className="text-xl font-bold">Coming Soon</h3>

            <div className={`flex`}>
              <div
                className="width-[280px] flex items-center justify-between"
                style={{ width: "100%" }}
              >
                <p
                  className="text-[18px] text-black "
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {jackpot.name}
                </p>
                <div className="relative items-center">
                  <div className="inline-flex items-start text-lg font-semibold text-black">
                    <div className="mt-[5px] flex justify-center">
                      <StarBorderOutlinedIcon sx={{ fontSize: "20px", marginTop: "4px" }} />
                      <span className="mr-2 text-[18px]"> New</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-grey font-light" style={{ color: "grey", height: "24px" }}>
              Join now to win the jackpot
            </p>
            <button
              style={{ fontSize: "22px" }}
              className="brand-color-opaque mt-2 flex h-10 w-full content-center justify-center rounded-lg px-[6px] py-[6px] text-[15px] font-bold text-brand"
            >
              <span
                className="text-[20px]"
                style={{
                  marginTop: "-1.5px",
                  background:
                    "linear-gradient(104.63deg, #C19311 0%, #EBCB73 48.96%, #EAAC00 98.54%)",
                  WebkitBackgroundClip: "text",
                  MozBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                Earn {formatCurrency(Number(jackpot.points))} MIKI points
              </span>
            </button>
          </div>
        </div>
      </article>
    </>
  );
};

export default JackpotCard;
