import { Reviews } from "src/types/models";
import * as s3Client from "src/services/s3Client.service";

/**
 * Delete undefined keys of the object
 * @param {object} object
 * @returns object
 */
export const deleteUndefined = (obj: Record<string, unknown>) => {
  return Object.keys(obj)
    .filter((key) => obj[key] === undefined)
    .forEach((key) => delete obj[key]);
};

export const formatCurrency = (value: number, decimals = 0) => {
  const formatter = Intl.NumberFormat("en-US");
  return formatter.format((+value).toFixed(decimals));
};

/**
 * Return reviews ordered by option
 * @param reviews
 * @param option
 * @returns reviews orders by option
 */
type sortingOptionType = "RECENT" | "OLDEST";
export function sortReviewsByDate(
  reviews: Reviews[],
  sortingOption?: sortingOptionType,
): Reviews[] {
  if (!reviews || reviews.length === 0) {
    return [];
  }
  return reviews.sort((a, b) => {
    switch (sortingOption) {
      case "OLDEST":
        return new Date(a.modified_at).getTime() - new Date(b.modified_at).getTime();
      case "RECENT":
      default:
        return new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime();
    }
  });
}

//returns the url of the image
export async function uploadImage(fileUpload: string, imageData: string, opportunityId: string) {
  if (fileUpload !== "") {
    return await s3Client.uploadImage(fileUpload, imageData, opportunityId);
  } else {
    return imageData;
  }
}

//take a date and return a new date object representing the same day but with the time set to midnight in the UTC timezone.
//input Sun Feb 18 2024 00:00:00 GMT+0300
//output Sun Feb 18 2024 03:00:00 GMT+0300
//which is equivalent to Sun Feb 18 2024 00:00:00 UTC
//the output is being converted to ISOString
export const offsetTimeZone = (date) => {
  const dateInUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  dateInUTC.setUTCHours(0, 0, 0, 0);
  return dateInUTC.toISOString();
};

/**
 * Converts mention strings of the format @[Name](ID|Type) to HTML anchor tags.
 * The href is based on the ID and type ('user' or 'business')
 *
 * @param {string} text - The input string containing mention patterns.
 * @returns {string} - The string with mentions replaced by styled anchor tags.
 *
 * @example
 * const mentionString = "@[Alice](abc123|user) and @[Bob's Business](xyz456|business)";
 * const result = convertMentionsToAnchors(mentionString);
 * // Output:
 * // '<a href="/member/abc123" style="font-weight: bold; color: #EAAC00;" target="_blank">Alice</a> and
 * // <a href="/member-business/xyz456" style="font-weight: bold; color: #EAAC00;" target="_blank">Bob\'s Business</a>'
 */
export const convertMentionsToAnchors = (text: string) => {
  const mentionRegex = /@\[(.+?)\]\((.+?)\|(.+?)\)/g;

  return text.replace(mentionRegex, (match, name, id, type) => {
    const url = type === "business" ? `/member-business/${name.trim().replace(/ |-/g, "_")}-${id}` : `/member/${name.trim().replace(/ |-/g, "_")}-${id}`;
    const target = typeof window !== "undefined" && window.innerWidth < 768 ? "_self" : "_blank";
    return `<a href="${url}" style="font-weight: bold; white-space: pre-line; color: #EAAC00;" target="${target}">${name}</a>`;
  });
};

/**
 * Extracts the UUID from the slug if available.
 * Handles slugs with extra characters and URL encoding.
 * 
 * @param slug - The slug to extract the ID from.
 * @returns The extracted UUID or null if invalid.
 */
export const getIdFromSlug = (slug: string): string | null => {
  if (!slug) return null;

  const decodedSlug = decodeURIComponent(slug);

  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  // Case 1: Handle new format (e.g., "username-uuid")
  if (decodedSlug.includes("-")) {
    const parts = decodedSlug.split("-");

    let uuidCandidate = "";
    const uuidParts: string[] = [];

    parts.forEach(part => {
      if (/^[0-9a-f]{8}$|^[0-9a-f]{4}$|^[0-9a-f]{12}$/i.test(part)) {
        uuidParts.push(part);
      }
    });

    uuidCandidate = uuidParts.join("-");
    if (uuidRegex.test(uuidCandidate)) {
      return uuidCandidate;
    }
  }

  // Case 2: Check if the entire slug is a valid UUID
  if (uuidRegex.test(decodedSlug)) {
    return decodedSlug;
  }

  return null;
};

export const extractMentionNames = (text: string) => {
  const mentionRegex = /@\[(.+?)\]\((.+?)\|(.+?)\)/g;

  return text.replace(mentionRegex, (match, name) => name); // Replaces each mention with just the name
};
