import { OpportunityPreview } from "../../types/models";
import OpportunityCard from "./OpportunityCard";
import JackpotCard from "./jackpotCards";

export default function HomeCard({
  opportunity,
  imagePriority,
}: {
  opportunity: OpportunityPreview;
  imagePriority: boolean;
}) {
  // Jackpots camulflage as opportunities
  if (opportunity.type === "jackpot") {
    const jackpot = {
      id: opportunity.opportunity_id,
      category: opportunity.category,
      name: opportunity.business_name,
      website: opportunity.business_website,
      points: opportunity.value,
      media: opportunity.media,
    };

    return <JackpotCard jackpot={jackpot} imagePriority={imagePriority} />;
  }

  return <OpportunityCard opportunity={opportunity} imagePriority={imagePriority} />;
}
