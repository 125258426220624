import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Link from "next/link";
export default function MobileFooter() {
  return (
    <div className="mt-5 block flex-col items-center border-t-[1px] border-gray-400 px-5 pb-2 text-2xl font-normal sm:hidden">
      <div className="w-full flex-col justify-start">
        <div className="mt-5 w-2/4 min-w-[215px] text-start text-lg font-thin">
          <Link className="mb-1 " href="/support/about">
            About us
          </Link>
          <br />
          <Link className="" href="/support/faq">
            MindKind FAQ
          </Link>
        </div>
        <div className="mt-5 w-2/4 min-w-[215px] text-start text-lg font-thin">
          <Link href="/support/privacy" className="mb-1">
            Privacy Policy
          </Link>
          <br />
          <Link href="/support/terms" >Terms & Conditions</Link>
        </div>
        <div className="mt-5 w-2/4 min-w-[215px] text-start text-lg font-thin">
          <Link href="/support/businesslaunch" className="mb-1 block">
            Business Launch Program
          </Link>
          <Link href="mailto:community@mindkind.life">
            community@mindkind.life
          </Link>
        </div>
      </div>
      <div className="mt-3 mb-6 text-start text-lg font-thin">
        {/* <div className="mb-1 ml-2">
                    Join Us
                </div> */}
        <div>
          <div className="flex justify-start">
            <Link
              target="_blank"
              rel="noopener"
              href="https://www.instagram.com/mindkind.life/"
            >
              <InstagramIcon sx={{ fontSize: "2.5rem", marginLeft: "-4px" }} />
            </Link>
            <Link
              target="_blank"
              rel="noopener"
              href="https://www.linkedin.com/company/mindkind-life/"
            >
              <LinkedInIcon sx={{ fontSize: "2.5rem" }} />
            </Link>
          </div>
        </div>
        <div className="mt-5 -mb-20 min-w-[215px] text-start text-lg font-thin text-gray-400">
          <p className="w-full">©2024 MindKind | All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
